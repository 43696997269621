import {
  TransactionTemplateStatus,
  TransactionTemplatesQueryVariables,
  useTransactionTemplatesQuery,
  useUserQuery,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import InlineFilterList from "@earnnest-e2-frontend/platform-ui/src/mantine/InlineFilterList"
import TransactionsTable from "@earnnest-e2-frontend/platform-views/src/mantine/TransactionsTable"
import { Space, Tabs } from "@mantine/core"
import moment from "moment"
import { useHistory, useLocation } from "react-router-dom"

export default function RequestsPage() {
  const history = useHistory()
  const location = useLocation()

  const userQuery = useUserQuery()
  const user = userQuery.data?.user

  const searchParams = new URLSearchParams(location.search)

  const sharedVariables: Partial<TransactionTemplatesQueryVariables> = {
    limit: 12,
    agentId: user?.id,
    // filters
    id: searchParams.get("id") || undefined,
    buyerFirstName: searchParams.get("buyer_first_name") || undefined,
    buyerLastName: searchParams.get("buyer_last_name") || undefined,
    buyerEmail: searchParams.get("buyer_email") || undefined,
    buyerPhone: searchParams.get("buyer_phone") || undefined,
    startAt: searchParams.get("start_at")
      ? moment(searchParams.get("start_at")).startOf("day").toISOString()
      : undefined,
    endAt: searchParams.get("end_at")
      ? moment(searchParams.get("end_at")).endOf("day").toISOString()
      : undefined,
  }

  const allQuery = useTransactionTemplatesQuery({
    notifyOnNetworkStatusChange: true, // so loading status updates for pagination
    variables: {
      ...sharedVariables,
    },
  })

  const requestedPendingQuery = useTransactionTemplatesQuery({
    variables: {
      ...sharedVariables,
      status: TransactionTemplateStatus.RequestedAndPending,
    },
  })

  const authorizedInitiatedQuery = useTransactionTemplatesQuery({
    variables: {
      ...sharedVariables,
      status: TransactionTemplateStatus.AuthorizedAndInitiated,
    },
  })

  const depositedQuery = useTransactionTemplatesQuery({
    variables: {
      ...sharedVariables,
      status: TransactionTemplateStatus.Deposited,
    },
  })

  const canceledFailedQuery = useTransactionTemplatesQuery({
    variables: {
      ...sharedVariables,
      status: TransactionTemplateStatus.CanceledAndFailed,
    },
  })

  const queryByTab = {
    All: allQuery,
    "Requested & Pending": requestedPendingQuery,
    "Authorized & Initiated": authorizedInitiatedQuery,
    Deposited: depositedQuery,
    "Canceled & Failed": canceledFailedQuery,
  }

  return (
    <>
      <InlineFilterList
        filters={{
          id: { label: "Transaction ID", type: "id" },
          buyer_first_name: { label: "Buyer First Name", type: "text" },
          buyer_last_name: { label: "Buyer Last Name", type: "text" },
          buyer_email: { label: "Buyer Email", type: "text" },
          buyer_phone: { label: "Buyer Phone", type: "text" },
          start_at: { label: "Requested After", type: "date" },
          end_at: { label: "Requested Before", type: "date" },
        }}
      />
      <Space h="lg" />
      <Tabs
        value={searchParams.get("tab") || "All"}
        onTabChange={(tab) => {
          searchParams.set("tab", tab)
          history.replace({
            pathname: location.pathname,
            search: searchParams.toString(),
            state: location.state,
          })
        }}>
        <Tabs.List>
          {Object.keys(queryByTab).map((tab) => (
            <Tabs.Tab key={tab} value={tab}>
              {tab}
            </Tabs.Tab>
          ))}
        </Tabs.List>
      </Tabs>
      <Space h="lg" />
      <TransactionsTable
        query={queryByTab[searchParams.get("tab")] || allQuery}
        getItemLink={(transactionTemplate) => ({
          pathname: `/requests/${transactionTemplate.id}`,
          search: location.search,
          state: location.state,
        })}
        viewerRole="agent"
      />
    </>
  )
}
