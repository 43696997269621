import {
  UserFragment,
  UserPermission,
  useUpdateUserMutation,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import UserAvatar from "@earnnest-e2-frontend/platform-ui/src/mantine/UserAvatar"
import {
  Button,
  Group,
  Radio,
  Space,
  Stack,
  Text,
  TextInput,
} from "@mantine/core"
import { useForm, yupResolver } from "@mantine/form"
import { useLocalStorage } from "@mantine/hooks"
import { notifications } from "@mantine/notifications"
import { useEffect } from "react"
import * as yup from "yup"

export default function UserProfileForm({ user }: { user: UserFragment }) {
  const hasAgentRole = user?.roles?.includes(UserPermission.RequestEscrow)

  const [defaultAgentRole, setDefaultAgentRole] = useLocalStorage<
    "buyer" | "listing" | ""
  >({
    key: "default-agent-role",
    defaultValue: undefined,
  })

  const form = useForm({
    initialValues: {
      firstName: user.firstName || "",
      lastName: user.lastName || "",
      defaultAgentRole: defaultAgentRole || "",
    },
    validate: yupResolver(
      yup.object({
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        defaultAgentRole: yup.string(),
      }),
    ),
  })

  useEffect(() => {
    form.setFieldValue("defaultAgentRole", defaultAgentRole || "")
  }, [defaultAgentRole]) // eslint-disable-line react-hooks/exhaustive-deps

  const [updateUser, updateUserState] = useUpdateUserMutation()

  return (
    <form
      onSubmit={form.onSubmit(async (values) => {
        console.log("SUBMIT", values)
        setDefaultAgentRole(values.defaultAgentRole || "")
        try {
          await updateUser({
            variables: {
              id: user.id,
              firstName: values.firstName,
              lastName: values.lastName,
              // TODO: skip these variables
              email: user.email,
              phone: user.phone,
              status: user.status,
            },
          })
          notifications.show({
            title: "Profile updated",
            message: "Your profile has been updated",
            color: "green",
          })
        } catch (error) {
          notifications.show({
            title: "Error",
            message: error.message,
            color: "red",
          })
        }
      })}>
      <Stack>
        <UserAvatar user={user} size={64} />
        <Group grow align="start">
          <TextInput label="First name" {...form.getInputProps("firstName")} />
          <TextInput label="Last name" {...form.getInputProps("lastName")} />
        </Group>
        <TextInput label="Email" value={user.email} disabled />
        <TextInput label="Phone" value={user.phone} disabled />
        {hasAgentRole ? (
          <Radio.Group
            label="Will you have payer information?"
            {...form.getInputProps("defaultAgentRole")}>
            <Stack pl={8} mt={8}>
              <Radio
                value="buyer"
                label={
                  <Stack spacing={0}>
                    <Text>Yes, I always have the payer’s info</Text>
                    <Text size="sm" color="dimmed">
                      You can send the payment info yourself.
                    </Text>
                  </Stack>
                }
              />
              <Radio
                value="listing"
                label={
                  <Stack spacing={0}>
                    <Text>No, I never have the payer’s info</Text>
                    <Text size="sm" color="dimmed">
                      You can have someone else finish the request.
                    </Text>
                  </Stack>
                }
              />
              <Radio
                value=""
                label={
                  <Stack spacing={0}>
                    <Text>It varies, ask me at each request</Text>
                    <Text size="sm" color="dimmed">
                      You can decide what’s best for each request.
                    </Text>
                  </Stack>
                }
              />
            </Stack>
          </Radio.Group>
        ) : null}
        <Space h="xl" />
        <Button type="submit" color="green" loading={updateUserState.loading}>
          Save Profile
        </Button>
      </Stack>
    </form>
  )
}
