import {
  namedOperations,
  useRequestUserRolesMutation,
  UserPermission,
  useUserWithOrganizationPermissionsQuery,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import WingPatternImage from "@earnnest-e2-frontend/platform-ui/src/assets/images/wing-pattern.svg"
import Wordmark from "@earnnest-e2-frontend/platform-ui/src/Earnnest/Wordmark"
import LoadingOverlay from "@earnnest-e2-frontend/platform-ui/src/LoadingOverlay/LoadingOverlay"
import {
  BackgroundImage,
  Box,
  Center,
  Divider,
  NavLink,
  Paper,
  ScrollArea,
  Space,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core"
import { useViewportSize } from "@mantine/hooks"
import pluralize from "pluralize"
import { useEffect } from "react"
import { RiArrowDropRightLine } from "react-icons/ri"
import { useHistory, useParams } from "react-router-dom"

export default function RolePage() {
  const theme = useMantineTheme()
  const userQuery = useUserWithOrganizationPermissionsQuery()
  const user = userQuery.data?.user
  const params = useParams()
  const history = useHistory()
  const { height: vph } = useViewportSize()

  const [requestUserRoles] = useRequestUserRolesMutation({
    variables: {
      roles: [UserPermission.RequestEscrow],
    },
    refetchQueries: [namedOperations.Query.User],
  })

  const hasAgentRole = user?.roles?.includes(UserPermission.RequestEscrow)

  useEffect(() => {
    if (user && params.sso) {
      if (!hasAgentRole) {
        console.log("Setting request roles for sso user, [%o]", user)
        requestUserRoles().then(() => {
          console.log("Redirecting sso user to requests, [%o]", user)
          history.replace("/requests")
        })
      } else {
        console.log("Redirecting sso user to requests, [%o]", user)
        history.replace("/requests")
      }
    }
  }, [params.sso, hasAgentRole, user, history, requestUserRoles])

  if (!user) {
    return null
  }

  const orgIdsWithAdminRole = user.organizationPermissions
    ?.filter((ou) => ou.roles?.includes(UserPermission.Admin))
    .map((ou) => ou.organizationId)

  const orgsWithAdminRole = user.organizations?.filter((o) =>
    orgIdsWithAdminRole.includes(o.id),
  )

  if (orgsWithAdminRole?.length === 1) {
    console.log(
      "Redirecting admin of single org to that org's dashboard, [%o]",
      user,
    )
    const org = orgsWithAdminRole[0]
    window.location.href = `${process.env.REACT_APP_DASHBOARD_DOMAIN}/${org.slug}`
    return <LoadingOverlay />
  }

  if (
    !user?.roles?.includes(UserPermission.RequestEscrow) &&
    orgsWithAdminRole?.length === 0
  ) {
    console.log("Redirecting new user to registration, [%o]", user)
    window.location.href = `${process.env.REACT_APP_REGISTRATION_DOMAIN}`
    return <LoadingOverlay />
  }

  if (
    user?.roles?.includes(UserPermission.RequestEscrow) &&
    orgsWithAdminRole?.length === 0
  ) {
    console.log("Redirecting agent to user dashboard, [%o]", user)
    history.replace("/requests")
    return <LoadingOverlay />
  }

  return (
    <BackgroundImage
      src={WingPatternImage}
      sx={(theme) => ({
        height: "100vh",
        position: "relative",
        backgroundColor: theme.fn.themeColor("dark.7"),
        backgroundRepeat: "no-repeat",
        backgroundPosition: "70px 50%",
      })}>
      <Center h="100vh">
        <Stack align="center" justify="flex-end" py="lg" h="100%">
          <Stack align="center" justify="center" sx={{ flex: "1 1 auto" }}>
            <Paper
              bg="dark.6"
              maw={450}
              component={ScrollArea.Autosize}
              type="scroll"
              mah={vph - 20 - 20 - 12 - 20} // padding and earnnest watermark
              radius={12}
              shadow="sm"
              p={48}>
              <Stack w="100%" align="center" justify="start" pos="relative">
                <Title size="h2" order={2}>
                  What would you like to do?
                </Title>
                <Space h={8} />
                <Text>
                  Looks like you have multiple capabilities with Earnnest.
                </Text>
                <Space h={32} />
                <Box>
                  {user.roles.includes(UserPermission.RequestEscrow) ? (
                    <>
                      <Title size="h6" order={6} color="dark.2">
                        AGENT
                      </Title>
                      <NavLink
                        component="a"
                        href={`${process.env.REACT_APP_AGENT_DOMAIN}/requests`}
                        rightSection={<RiArrowDropRightLine />}
                        label="Request Payment"
                      />
                      <Space h={4} />
                      <Divider />
                      <Space h={8} />
                    </>
                  ) : null}
                  {orgsWithAdminRole?.length ? (
                    <>
                      <Title size="h6" order={6} color="dark.2">
                        {pluralize("ORGANIZATION", orgsWithAdminRole.length)}
                      </Title>
                      {orgsWithAdminRole.map((org) => (
                        <NavLink
                          component="a"
                          key={org.id}
                          href={`${process.env.REACT_APP_DASHBOARD_DOMAIN}/${org.slug}`}
                          rightSection={<RiArrowDropRightLine />}
                          label={org.name}
                        />
                      ))}
                      <Space h={4} />
                      <Divider />
                      <Space h={8} />
                    </>
                  ) : null}
                  {user.role === "superadmin" ? (
                    <>
                      <Title size="h6" order={6} color="dark.2">
                        SUPERADMIN
                      </Title>
                      <NavLink
                        component="a"
                        href={process.env.REACT_APP_SUPPORT_DOMAIN}
                        rightSection={<RiArrowDropRightLine />}
                        label="Manage Earnnest"
                      />
                      <Space h={4} />
                      <Divider />
                      <Space h={8} />
                    </>
                  ) : null}
                </Box>
              </Stack>
            </Paper>
          </Stack>
          <Wordmark
            color={theme.fn.themeColor("dark.2")}
            style={{ height: 12 }}
          />
        </Stack>
      </Center>
    </BackgroundImage>
  )
}
